function App() {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center lg:grid lg:grid-cols-2 ">
      <div className="w-full h-full flex flex-col items-center justify-center bg-[#24003E]">
        <a
          href="https://maymarket.aydtanitim.com/"
          className="flex items-center justify-center"
        >
          <img
            src="/resim/logomor.png"
            alt="Image1"
            className="p-3 w-[40%] object-contain hover:scale-110  transition-transform duration-300"
          />
        </a>
        <a
          href="https://maymarket.aydtanitim.com/"
          className="flex items-center justify-center"
        >
          <div className="mt-12 md:mb-12 bg-[#FCB63D] px-6 py-2 rounded-xl cursor-pointer">
            <div className="text-[#24003E] text-2xl font-extrabold text-center">
              Online Alışveriş Sitemiz
            </div>
          </div>
        </a>
      </div>
      <div className="w-full h-full flex flex-col items-center justify-center bg-[#FCB63D]">
        <a
          href="https://mayrotasyon.aydtanitim.com/"
          className="flex items-center justify-center"
        >
          <img
            src="/resim/logosari.png"
            alt="Image2"
            className="p-3 w-[40%] object-contain hover:scale-110  transition-transform duration-300"
          />
        </a>
        <a
          href="https://mayrotasyon.aydtanitim.com/"
          className="flex items-center justify-center"
        >
          <div className="mt-12 md:mb-12 bg-[#24003E] px-6 py-2 rounded-xl cursor-pointer">
            <div className="text-[#FCB63D] text-2xl font-extrabold text-center">
              Plastik Rotasyon Çözümlerimiz
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default App;
